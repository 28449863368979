@import url("https://fonts.googleapis.com/css?family=Press+Start+2P");

#main-canvas {
}

#yfilter {
  /* background-color: black; */
}

#main_svg {
  padding: 10px 10px;
}

#trivia,
#trivia-sec {
  color: white;
  font-family: "Press Start 2P", cursive;
  font-size: 10px;
  opacity: 0.8;
}

.ant-btn.timeline-btn {
  border: 1px solid white;
  border-radius: 0;
  color: white;
  text-transform: uppercase;
  font-family: "Press Start 2P", cursive;
  font-size: 12px;
  background-color: black;
  margin-top: -10px;
  margin-right: 78vw;
  transition: color 0.5s, border 0.5s, background-color 0.5s;
}

.ant-btn.timeline-btn:hover {
  color: black;
  border: none;
  background-color: white;
}

.ant-btn.my-clear-btn {
  border: 1px solid white;
  border-radius: 0;
  color: white;
  text-transform: uppercase;
  font-family: "Press Start 2P", cursive;
  font-size: 12px;
  background-color: black;
  margin-top: 15px;
  margin-right: 50vw;
  transition: color 0.5s, border 0.5s, background-color 0.5s;
}

.ant-btn.my-clear-btn:hover,
.ant-btn.my-clear-btn:focus {
  color: black;
  border: none;
  background-color: white;
}

.ant-btn.tooltip {
  border: 1px solid white;
  border-radius: 0;
  color: white;
  text-transform: uppercase;
  font-family: "Press Start 2P", cursive;
  font-size: 12px;
  background-color: black;
  width: auto;
}

.ant-btn.tooltip:hover,
.ant-btn.tooltip:focus {
  color: black;
  border: none;
  background-color: white;
}

.ant-select.my-select {
  width: 400px;
  float: right;
  font-family: "Press Start 2P", cursive;
  font-size: 12px;
  background-color: black;
  border-color: white;
  color: white;
  margin-top: 20px;
  margin-right: 60px;
  transition: box-shadow 0.3s;
}

.ant-select.my-select:hover {
  border-color: white;
  box-shadow: 2px 2px white;
}

.ant-select.my-select > .ant-select-selection.ant-select-selection--single {
  border: 1px solid white;
  border-radius: 0px !important;
  font-family: "Press Start 2P", cursive;
  font-size: 12px;
  background-color: black;
  border-color: white;
  color: white;
}

.ant-select.my-select
  > .ant-select-selection.ant-select-selection--single
  > .ant-select-selection__rendered
  > .ant-select-search {
  color: white;
}

.ant-select-dropdown.my-dropdown {
  font-family: "Press Start 2P", cursive;
  font-size: 10px;
  border-radius: 0px;
  opacity: 0.8;
}

.ant-input.my-input {
  width: 400px;
  float: right;
  font-family: "Press Start 2P", cursive;
  font-size: 12px;
  background-color: black;
  border-color: white;
  color: white;
  margin-top: 20px;
}

.ant-input.my-input:hover {
  border-color: white;
  border-width: 1.5px;
}

.ant-input.my-input:focus {
  border-color: white;
  border-width: 1.5px;
}

.ant-radio-group.my-btn-group {
  padding: 10px;
}

.ant-radio-button-wrapper.my-btn {
  width: 300px !important;
  float: left;
  font-family: "Press Start 2P", cursive;
  font-size: 12px;
  background-color: black;
  color: white;
  border-color: white;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
}

.ant-radio-button-wrapper.my-btn:hover,
.ant-radio.button-wrapper.my-btn:focus {
  color: black;
  background-color: white;
  border-color: white;
}

.ant-radio-button-wrapper.my-btn1:hover,
.ant-radio-button-wrapper.my-btn1:focus {
  color: black;
  background-color: white;
  border-color: white;
}

.ant-radio-button-wrapper.my-btn:hover,
.ant-radio-button-wrapper.my-btn:focus {
  border-color: white;
}

.ant-radio-button-wrapper-checked.my-btn:first-child {
  border-color: white;
  background-color: white;
  color: black;
}

.ant-radio-button-wrapper-checked.my-btn {
  box-shadow: none;
  background-color: white;
  color: black;
}

.ant-radio-group.my-btn-group.my-btn:first-child:not(:last-child) {
  border-right-color: white;
}

.ant-radio-group.my-btn-group.my-btn:first-child:not(:last-child):hover {
  border-right-color: black;
}

.ant-radio-group.my-btn-group.my-btn:last-child:not(:first-child) {
  border-left-color: white;
}

.ant-radio-group.my-btn-group.my-btn:last-child:not(:first-child):hover {
  border-left-color: black;
}

.ant-radio-group.my-btn-group.my-btn:not(:last-child):not(:first-child) {
  border-left-color: white;
  border-right-color: white;
}

.loading {
  margin: auto;
  width: 100vw;
  height: 100vh;
  background-color: black;
}
.loading .ant-spin-dot i {
  background-color: #eeeeee;
}
.loading .ant-spin {
  /* padding-left: 28vw; */
  padding-right: 6vw;
  padding-top: 30vh;
}
.loading #loading-text {
  color: white;
  font-family: "Press Start 2P", cursive;
  text-align: center;
  text-transform: uppercase;
  margin-top: 20px;
  margin-right: 6.5vw;
  opacity: 0.8;
}

.cur-year-text {
  color: white;
  font-family: "Press Start 2P", cursive;
  text-align: center;
}

div.tooltip {
  color: white;
  font-size: 12px;
  font-family: "Press Start 2P", cursive;
  position: absolute;
  text-align: center;
  min-width: 15vw;
  width: auto;
  height: auto;
  padding: 10px;
  background: black;
  border: 2px solid white;
  pointer-events: none;
}

.ant-modal.my-modal {
  width: 50vw !important;
  height: auto;
}

.ant-modal.my-modal > .ant-modal-content {
  color: white !important;
  font-size: 12px;
  font-family: "Press Start 2P", cursive;
  background: black !important;
  border-radius: 0px;
  border: 2px solid white;
}

.ant-modal.my-modal > .ant-modal-content > .ant-modal-header {
  color: white;
  /* font-size: 12px; */
  font-family: "Press Start 2P", cursive;
  background: black !important;
  border-radius: 0px;
  /* border-bottom: none; */
  /* border: 2px solid white; */
}

.ant-modal.my-modal
  > .ant-modal-content
  > .ant-modal-header
  > .ant-modal-title {
  color: white;
}

.ant-modal.my-modal > .ant-modal-content > .ant-modal-footer > .ant-btn {
  background-color: black;
  color: white;
  border: 1px solid white;
  border-radius: 0px;
  transition: background-color 0.3s color 0.3s;
}

.ant-modal.my-modal > .ant-modal-content > .ant-modal-footer > .ant-btn:hover {
  background-color: white;
  color: black;
}

.ant-modal.my-modal > .ant-modal-content > .ant-modal-close {
  display: none;
}

.my-modal-header-img {
  width: 46.2vw;
  height: auto;
  border: 1px solid lightgrey;
  margin: auto !important;
  display: block;
}

.ant-slider.my-slider {
  margin-left: 4vw;
  margin-right: 4vw;
}

.ant-slider-track {
  background-color: grey !important;
}

.ant-slider-mark-text {
  color: white !important;
  font-family: "Press Start 2P", cursive;
  font-size: 10px;
  padding-top: 10px;
}

.ant-slider-handle {
  border: 2px solid grey !important;
}

.ant-slider-handle:focus {
  box-shadow: 0 0 0 5px grey !important;
}

.ant-slider-dot-active {
  border-color: grey !important;
}

.ant-tooltip-inner {
  color: white !important;
  font-family: "Press Start 2P", cursive;
  font-size: 10px;
}
