@import url(https://fonts.googleapis.com/css?family=Press+Start+2P);
@import url(https://fonts.googleapis.com/css?family=Press+Start+2P);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#main-canvas {
}

#yfilter {
  /* background-color: black; */
}

#main_svg {
  padding: 10px 10px;
}

#trivia,
#trivia-sec {
  color: white;
  font-family: "Press Start 2P", cursive;
  font-size: 10px;
  opacity: 0.8;
}

.ant-btn.timeline-btn {
  border: 1px solid white;
  border-radius: 0;
  color: white;
  text-transform: uppercase;
  font-family: "Press Start 2P", cursive;
  font-size: 12px;
  background-color: black;
  margin-top: -10px;
  margin-right: 78vw;
  -webkit-transition: color 0.5s, border 0.5s, background-color 0.5s;
  transition: color 0.5s, border 0.5s, background-color 0.5s;
}

.ant-btn.timeline-btn:hover {
  color: black;
  border: none;
  background-color: white;
}

.ant-btn.my-clear-btn {
  border: 1px solid white;
  border-radius: 0;
  color: white;
  text-transform: uppercase;
  font-family: "Press Start 2P", cursive;
  font-size: 12px;
  background-color: black;
  margin-top: 15px;
  margin-right: 50vw;
  -webkit-transition: color 0.5s, border 0.5s, background-color 0.5s;
  transition: color 0.5s, border 0.5s, background-color 0.5s;
}

.ant-btn.my-clear-btn:hover,
.ant-btn.my-clear-btn:focus {
  color: black;
  border: none;
  background-color: white;
}

.ant-btn.tooltip {
  border: 1px solid white;
  border-radius: 0;
  color: white;
  text-transform: uppercase;
  font-family: "Press Start 2P", cursive;
  font-size: 12px;
  background-color: black;
  width: auto;
}

.ant-btn.tooltip:hover,
.ant-btn.tooltip:focus {
  color: black;
  border: none;
  background-color: white;
}

.ant-select.my-select {
  width: 400px;
  float: right;
  font-family: "Press Start 2P", cursive;
  font-size: 12px;
  background-color: black;
  border-color: white;
  color: white;
  margin-top: 20px;
  margin-right: 60px;
  -webkit-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
}

.ant-select.my-select:hover {
  border-color: white;
  box-shadow: 2px 2px white;
}

.ant-select.my-select > .ant-select-selection.ant-select-selection--single {
  border: 1px solid white;
  border-radius: 0px !important;
  font-family: "Press Start 2P", cursive;
  font-size: 12px;
  background-color: black;
  border-color: white;
  color: white;
}

.ant-select.my-select
  > .ant-select-selection.ant-select-selection--single
  > .ant-select-selection__rendered
  > .ant-select-search {
  color: white;
}

.ant-select-dropdown.my-dropdown {
  font-family: "Press Start 2P", cursive;
  font-size: 10px;
  border-radius: 0px;
  opacity: 0.8;
}

.ant-input.my-input {
  width: 400px;
  float: right;
  font-family: "Press Start 2P", cursive;
  font-size: 12px;
  background-color: black;
  border-color: white;
  color: white;
  margin-top: 20px;
}

.ant-input.my-input:hover {
  border-color: white;
  border-width: 1.5px;
}

.ant-input.my-input:focus {
  border-color: white;
  border-width: 1.5px;
}

.ant-radio-group.my-btn-group {
  padding: 10px;
}

.ant-radio-button-wrapper.my-btn {
  width: 300px !important;
  float: left;
  font-family: "Press Start 2P", cursive;
  font-size: 12px;
  background-color: black;
  color: white;
  border-color: white;
  -webkit-transition: color 0.3s, background-color 0.3s, border-color 0.3s;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
}

.ant-radio-button-wrapper.my-btn:hover,
.ant-radio.button-wrapper.my-btn:focus {
  color: black;
  background-color: white;
  border-color: white;
}

.ant-radio-button-wrapper.my-btn1:hover,
.ant-radio-button-wrapper.my-btn1:focus {
  color: black;
  background-color: white;
  border-color: white;
}

.ant-radio-button-wrapper.my-btn:hover,
.ant-radio-button-wrapper.my-btn:focus {
  border-color: white;
}

.ant-radio-button-wrapper-checked.my-btn:first-child {
  border-color: white;
  background-color: white;
  color: black;
}

.ant-radio-button-wrapper-checked.my-btn {
  box-shadow: none;
  background-color: white;
  color: black;
}

.ant-radio-group.my-btn-group.my-btn:first-child:not(:last-child) {
  border-right-color: white;
}

.ant-radio-group.my-btn-group.my-btn:first-child:not(:last-child):hover {
  border-right-color: black;
}

.ant-radio-group.my-btn-group.my-btn:last-child:not(:first-child) {
  border-left-color: white;
}

.ant-radio-group.my-btn-group.my-btn:last-child:not(:first-child):hover {
  border-left-color: black;
}

.ant-radio-group.my-btn-group.my-btn:not(:last-child):not(:first-child) {
  border-left-color: white;
  border-right-color: white;
}

.loading {
  margin: auto;
  width: 100vw;
  height: 100vh;
  background-color: black;
}
.loading .ant-spin-dot i {
  background-color: #eeeeee;
}
.loading .ant-spin {
  /* padding-left: 28vw; */
  padding-right: 6vw;
  padding-top: 30vh;
}
.loading #loading-text {
  color: white;
  font-family: "Press Start 2P", cursive;
  text-align: center;
  text-transform: uppercase;
  margin-top: 20px;
  margin-right: 6.5vw;
  opacity: 0.8;
}

.cur-year-text {
  color: white;
  font-family: "Press Start 2P", cursive;
  text-align: center;
}

div.tooltip {
  color: white;
  font-size: 12px;
  font-family: "Press Start 2P", cursive;
  position: absolute;
  text-align: center;
  min-width: 15vw;
  width: auto;
  height: auto;
  padding: 10px;
  background: black;
  border: 2px solid white;
  pointer-events: none;
}

.ant-modal.my-modal {
  width: 50vw !important;
  height: auto;
}

.ant-modal.my-modal > .ant-modal-content {
  color: white !important;
  font-size: 12px;
  font-family: "Press Start 2P", cursive;
  background: black !important;
  border-radius: 0px;
  border: 2px solid white;
}

.ant-modal.my-modal > .ant-modal-content > .ant-modal-header {
  color: white;
  /* font-size: 12px; */
  font-family: "Press Start 2P", cursive;
  background: black !important;
  border-radius: 0px;
  /* border-bottom: none; */
  /* border: 2px solid white; */
}

.ant-modal.my-modal
  > .ant-modal-content
  > .ant-modal-header
  > .ant-modal-title {
  color: white;
}

.ant-modal.my-modal > .ant-modal-content > .ant-modal-footer > .ant-btn {
  background-color: black;
  color: white;
  border: 1px solid white;
  border-radius: 0px;
  -webkit-transition: background-color 0.3s color 0.3s;
  transition: background-color 0.3s color 0.3s;
}

.ant-modal.my-modal > .ant-modal-content > .ant-modal-footer > .ant-btn:hover {
  background-color: white;
  color: black;
}

.ant-modal.my-modal > .ant-modal-content > .ant-modal-close {
  display: none;
}

.my-modal-header-img {
  width: 46.2vw;
  height: auto;
  border: 1px solid lightgrey;
  margin: auto !important;
  display: block;
}

.ant-slider.my-slider {
  margin-left: 4vw;
  margin-right: 4vw;
}

.ant-slider-track {
  background-color: grey !important;
}

.ant-slider-mark-text {
  color: white !important;
  font-family: "Press Start 2P", cursive;
  font-size: 10px;
  padding-top: 10px;
}

.ant-slider-handle {
  border: 2px solid grey !important;
}

.ant-slider-handle:focus {
  box-shadow: 0 0 0 5px grey !important;
}

.ant-slider-dot-active {
  border-color: grey !important;
}

.ant-tooltip-inner {
  color: white !important;
  font-family: "Press Start 2P", cursive;
  font-size: 10px;
}

#timeline_svg {
  padding: 10px 0px;
  /* opacity: 0.4; */
  height: auto;
}

div h1 {
  font-family: "Press Start 2P", cursive;
}

#trivia,
#trivia-sec {
  color: white;
  font-family: "Press Start 2P", cursive;
  font-size: 10px;
}

.ant-input.my-input {
  width: 400px;
  float: right;
  font-family: "Press Start 2P", cursive;
  font-size: 12px;
  background-color: black;
  border-color: white;
  color: white;
}

.ant-input.my-input:hover {
  border-color: white;
  border-width: 1.5px;
}

.ant-input.my-input:focus {
  border-color: white;
  border-width: 1.5px;
}

.ant-radio-group.my-btn-group {
  padding: 10px;
}

.ant-radio-button-wrapper.my-btn {
  width: 250px;
  float: left;
  font-family: "Press Start 2P", cursive;
  font-size: 12px;
  background-color: black;
  color: white;
  border-color: white;
}

.ant-radio-button-wrapper.my-btn:hover,
.ant-radio.button-wrapper.my-btn:focus {
  color: black;
  background-color: white;
  border-color: white;
}

.ant-radio-button-wrapper.my-btn1:hover,
.ant-radio-button-wrapper.my-btn1:focus {
  color: black;
  background-color: white;
  border-color: white;
}

.ant-radio-button-wrapper.my-btn:hover,
.ant-radio-button-wrapper.my-btn:focus {
  border-color: white;
}

.ant-radio-button-wrapper-checked.my-btn:first-child {
  border-color: white;
  background-color: white;
  color: black;
}

.ant-radio-button-wrapper-checked.my-btn {
  box-shadow: none;
  background-color: white;
  color: black;
}

.ant-radio-group.my-btn-group.my-btn:first-child:not(:last-child) {
  border-right-color: white;
}

.ant-radio-group.my-btn-group.my-btn:first-child:not(:last-child):hover {
  border-right-color: black;
}

.ant-radio-group.my-btn-group.my-btn:last-child:not(:first-child) {
  border-left-color: white;
}

.ant-radio-group.my-btn-group.my-btn:last-child:not(:first-child):hover {
  border-left-color: black;
}

.ant-radio-group.my-btn-group.my-btn:not(:last-child):not(:first-child) {
  border-left-color: white;
  border-right-color: white;
}

.loading {
  margin: auto;
}
.loading .ant-spin-dot i {
  background-color: #eeeeee;
}

.ant-carousel .slick-slide {
  text-align: center;
  height: 360px;
  line-height: 360px;
  background: grey;
  overflow: hidden;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}

.ant-modal.my-modal {
  width: 60vw !important;
  height: auto;
}

.ant-modal.my-modal
  > .ant-modal-content
  > .ant-modal-body
  > .chapter
  > .ant-steps
  > .ant-steps-item
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: white !important;
  font-family: "Press Start 2P", cursive;
  font-size: 12px;
}

.ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: #fff;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #fff;
}
.ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: #fff;
}
.ant-steps-item-wait
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: #fff;
}
.ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: #fff !important;
}
.ant-steps-item-tail::after {
  background-color: #fff;
}
.ant-steps-icon-dot {
  background-color: #fff;
}

.ant-steps-item .ant-steps-item-finish::-moz-selection {
  background: #fff;
}

.ant-steps-item .ant-steps-item-finish::selection {
  background: #fff;
}
.ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background-color: #fff;
}

.ant-steps-item-finish > .ant-steps-item-tail:after {
  background-color: #fff !important;
}

.ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: #fff !important;
}
/*.ant-modal.my-modal > .ant-modal-content > .chapter > .ant-steps > .ant-steps-item > .ant-steps-item-icon > .ant-steps-icon > .ant-steps-icon-dot {
  background-color: white;
  color: white !important; 
} */

/* .anticon.my-icon {
  cursor: pointer;
} */

.ant-btn.modal-btn {
  border: 2px solid white !important;
  background-color: black;
  color: white;
  font-family: "Press Start 2P", cursive;
  text-transform: uppercase;
  font-size: 20px;
  padding-bottom: 5px;
  margin: 0px 5px;
  height: auto;
  -webkit-transition: color 0.5s, background-color 0.5s, border 0.3s;
  transition: color 0.5s, background-color 0.5s, border 0.3s;
}

.ant-btn.modal-btn:hover {
  color: black;
  background-color: white;
  border: white;
}

#canvas-wrapper {
  position: relative;
  height: auto;
}

.select-order {
  width: 120px;
  position: relative;
  top: 130px;
  font-family: "Press Start 2P", cursive !important;
  font-size: 8px !important;
  border-radius: 0px !important;
  margin-top: 20px !important;
  margin-right: 60px !important;
}

.ant-select-dropdown.drop-order {
  font-family: "Press Start 2P", cursive !important;
  font-size: 8px !important;
}

/* #enter {
  position: absolute;
  width: 50vw;
  height: 100%;
  top: 50vh;
  left: 25vw;
} */

#enter {
  position: absolute;
  width: 50vw;
  top: 3vh;
  left: 25vw;
}

.ant-btn.my-btn {
  border-radius: 0;
  border: 2px solid white;
  background-color: black;
  color: white;
  font-family: "Press Start 2P", cursive;
  text-transform: uppercase;
  font-size: 20px;
  padding: 10px 10px;
  height: auto;
  width: 25vw;
  -webkit-transition: color 0.5s, background-color 0.5s, border 0.3s;
  transition: color 0.5s, background-color 0.5s, border 0.3s;
}

.ant-btn.my-btn:hover,
.ant-btn.my-btn:focus {
  color: black;
  background-color: white;
  border: white;
}

div p {
  color: white;
  text-align: center;
  font-family: "Press Start 2P", cursive;
  font-size: 10px;
  opacity: 0.8;
}

.App {
  text-align: center;
}

#components-layout-demo-fixed-sider .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

