@import url("https://fonts.googleapis.com/css?family=Press+Start+2P");

#timeline_svg {
  padding: 10px 0px;
  /* opacity: 0.4; */
  height: auto;
}

div h1 {
  font-family: "Press Start 2P", cursive;
}

#trivia,
#trivia-sec {
  color: white;
  font-family: "Press Start 2P", cursive;
  font-size: 10px;
}

.ant-input.my-input {
  width: 400px;
  float: right;
  font-family: "Press Start 2P", cursive;
  font-size: 12px;
  background-color: black;
  border-color: white;
  color: white;
}

.ant-input.my-input:hover {
  border-color: white;
  border-width: 1.5px;
}

.ant-input.my-input:focus {
  border-color: white;
  border-width: 1.5px;
}

.ant-radio-group.my-btn-group {
  padding: 10px;
}

.ant-radio-button-wrapper.my-btn {
  width: 250px;
  float: left;
  font-family: "Press Start 2P", cursive;
  font-size: 12px;
  background-color: black;
  color: white;
  border-color: white;
}

.ant-radio-button-wrapper.my-btn:hover,
.ant-radio.button-wrapper.my-btn:focus {
  color: black;
  background-color: white;
  border-color: white;
}

.ant-radio-button-wrapper.my-btn1:hover,
.ant-radio-button-wrapper.my-btn1:focus {
  color: black;
  background-color: white;
  border-color: white;
}

.ant-radio-button-wrapper.my-btn:hover,
.ant-radio-button-wrapper.my-btn:focus {
  border-color: white;
}

.ant-radio-button-wrapper-checked.my-btn:first-child {
  border-color: white;
  background-color: white;
  color: black;
}

.ant-radio-button-wrapper-checked.my-btn {
  box-shadow: none;
  background-color: white;
  color: black;
}

.ant-radio-group.my-btn-group.my-btn:first-child:not(:last-child) {
  border-right-color: white;
}

.ant-radio-group.my-btn-group.my-btn:first-child:not(:last-child):hover {
  border-right-color: black;
}

.ant-radio-group.my-btn-group.my-btn:last-child:not(:first-child) {
  border-left-color: white;
}

.ant-radio-group.my-btn-group.my-btn:last-child:not(:first-child):hover {
  border-left-color: black;
}

.ant-radio-group.my-btn-group.my-btn:not(:last-child):not(:first-child) {
  border-left-color: white;
  border-right-color: white;
}

.loading {
  margin: auto;
}
.loading .ant-spin-dot i {
  background-color: #eeeeee;
}

.ant-carousel .slick-slide {
  text-align: center;
  height: 360px;
  line-height: 360px;
  background: grey;
  overflow: hidden;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}

.ant-modal.my-modal {
  width: 60vw !important;
  height: auto;
}

.ant-modal.my-modal
  > .ant-modal-content
  > .ant-modal-body
  > .chapter
  > .ant-steps
  > .ant-steps-item
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: white !important;
  font-family: "Press Start 2P", cursive;
  font-size: 12px;
}

.ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: #fff;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #fff;
}
.ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: #fff;
}
.ant-steps-item-wait
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: #fff;
}
.ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: #fff !important;
}
.ant-steps-item-tail::after {
  background-color: #fff;
}
.ant-steps-icon-dot {
  background-color: #fff;
}

.ant-steps-item .ant-steps-item-finish::selection {
  background: #fff;
}
.ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background-color: #fff;
}

.ant-steps-item-finish > .ant-steps-item-tail:after {
  background-color: #fff !important;
}

.ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: #fff !important;
}
/*.ant-modal.my-modal > .ant-modal-content > .chapter > .ant-steps > .ant-steps-item > .ant-steps-item-icon > .ant-steps-icon > .ant-steps-icon-dot {
  background-color: white;
  color: white !important; 
} */

/* .anticon.my-icon {
  cursor: pointer;
} */

.ant-btn.modal-btn {
  border: 2px solid white !important;
  background-color: black;
  color: white;
  font-family: "Press Start 2P", cursive;
  text-transform: uppercase;
  font-size: 20px;
  padding-bottom: 5px;
  margin: 0px 5px;
  height: auto;
  transition: color 0.5s, background-color 0.5s, border 0.3s;
}

.ant-btn.modal-btn:hover {
  color: black;
  background-color: white;
  border: white;
}

#canvas-wrapper {
  position: relative;
  height: auto;
}

.select-order {
  width: 120px;
  position: relative;
  top: 130px;
  font-family: "Press Start 2P", cursive !important;
  font-size: 8px !important;
  border-radius: 0px !important;
  margin-top: 20px !important;
  margin-right: 60px !important;
}

.ant-select-dropdown.drop-order {
  font-family: "Press Start 2P", cursive !important;
  font-size: 8px !important;
}

/* #enter {
  position: absolute;
  width: 50vw;
  height: 100%;
  top: 50vh;
  left: 25vw;
} */

#enter {
  position: absolute;
  width: 50vw;
  top: 3vh;
  left: 25vw;
}

.ant-btn.my-btn {
  border-radius: 0;
  border: 2px solid white;
  background-color: black;
  color: white;
  font-family: "Press Start 2P", cursive;
  text-transform: uppercase;
  font-size: 20px;
  padding: 10px 10px;
  height: auto;
  width: 25vw;
  transition: color 0.5s, background-color 0.5s, border 0.3s;
}

.ant-btn.my-btn:hover,
.ant-btn.my-btn:focus {
  color: black;
  background-color: white;
  border: white;
}
